import {
  FunctionComponent,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';

interface Focus {
  id?: string;
  withScroll: boolean;
  setFocus: (id: string, withScroll?: boolean) => void;
}

const FocusContext = createContext<Focus>({
  withScroll: false,
  setFocus: (_: string) => ({}),
});

const AppWithFocus: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [id, setId] = useState<string>();
  const [withScroll, setWithScroll] = useState<boolean>(false);

  const setFocus = useCallback(
    (id: string, withScroll = false) => {
      setId(id);
      setWithScroll(withScroll);
    },
    [setId, setWithScroll]
  );

  return (
    <FocusContext.Provider value={{ id, withScroll, setFocus }}>
      {children}
    </FocusContext.Provider>
  );
};

export const useFocus = () => {
  return useContext<Focus>(FocusContext);
};

export default AppWithFocus;
