const idUrlSubstitution = {
  regExp: new RegExp('\\d+', 'g'),
  replacement: '_id_',
};

const tokenUrlSubstitution = {
  regExp: new RegExp('[A-Za-z0-9_-]{43}', 'g'),
  replacement: '_token_',
};

const uuidUrlSubstitution = {
  regExp: new RegExp(
    '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}',
    'g'
  ),
  replacement: '_uuid_',
};

/**
 * Important that tokenUrlSubstitiuon before id UrlSubstition,
 * because there are possible id's inside the tokens.
 */
const substitutions = [
  tokenUrlSubstitution,
  uuidUrlSubstitution,
  idUrlSubstitution,
];

/**
 * IMPORTANT: Remember to update subsituteIdsWithPlacedholders inside
 * script app/content/js/common/analytics/redactUrlSiteimproveScript.js
 * if you ever make changes to this function
 */
export const subsituteIdsWithPlacedholders = (path: string) => {
  return substitutions.reduce(
    (p, substitution) =>
      substitution.regExp[Symbol.replace](p, substitution.replacement),
    path
  );
};

const SIGNATURE_RESULT_QUERY_PARAM_NAME = 'signature_result';
const PAYMENT_RESULT_QUERY_PARAM_NAME = 'payment_result';

export const getCurrentUrlWithAnonymizedQueryParams = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const queryParamsWhitelist = [
    SIGNATURE_RESULT_QUERY_PARAM_NAME,
    PAYMENT_RESULT_QUERY_PARAM_NAME,
  ];

  queryParams.forEach(function (value, key) {
    if (!queryParamsWhitelist.includes(key)) {
      queryParams.set(key, 'anonymized');
    }
  });

  return `${window.location.hostname}${
    window.location.pathname
  }?${queryParams.toString()}`;
};
