import {
  createContext,
  useState,
  useEffect,
  FunctionComponent,
  useContext,
  PropsWithChildren,
} from 'react';
import { retrieve, save } from '../cookies/cookies';
import { nb, nn, enGB as en } from 'date-fns/locale';
import { format as dateFnsFormat } from 'date-fns';
import { Params, _S } from './translate';

import engelsk from './strings.en.json';
import bokmal from './strings.nb.json';
import nynorsk from './strings.nn.json';
import { interpolate } from './translate';
import { I18nProvider } from 'react-aria-components';

export type Language = 'en' | 'nb' | 'nn';
export type Translation = typeof engelsk;

export const translations: { [key in Language]: Translation } = {
  nb: bokmal,
  en: engelsk,
  nn: nynorsk,
};

const fnslocales = { nb, nn, en };

const htmlLangCodes: { [key in Language]: string } = {
  nb: 'nb-NO',
  nn: 'nn-NO',
  en: 'en',
};

const initialLang = retrieve('posten.signering.lang', 'nb') as Language;

const I18nContext = createContext({
  language: initialLang,
  setLanguage: (_: Language) => {
    return;
  },
});

export const useI18n: () => [
  translation: Translation,
  translate: (textKey: string, params?: Params) => string,
  format: (date: Date, format: string) => string,
  setLanguage: (language: Language) => void,
  language: Language,
  langCode: string
] = () => {
  const { language, setLanguage } = useContext<{
    language: Language;
    setLanguage: (language: Language) => void;
  }>(I18nContext);

  useEffect(() => {
    save('posten.signering.lang', language);
    document.documentElement.lang = htmlLangCodes[language];
  }, [language]);

  const format = (date: Date | string, formatStr: string) =>
    date === '' || date === undefined
      ? ''
      : dateFnsFormat(new Date(date), formatStr, {
          locale: fnslocales[language],
        });

  const translate = (textKey: string, params?: Params): string =>
    _S(textKey, language, params);

  const langCode = htmlLangCodes[language];

  return [
    translations[language],
    translate,
    format,
    setLanguage,
    language,
    langCode,
  ];
};

export const AppWithI18n: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const [language, setLanguage] = useState<Language>(initialLang);

  return (
    <I18nContext.Provider value={{ language, setLanguage }}>
      <I18nProvider locale={language}>{children}</I18nProvider>
    </I18nContext.Provider>
  );
};

export function withParams(text: string, params: Params): string {
  return interpolate(text, params);
}
