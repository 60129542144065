import {
  FunctionComponent,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from 'react';

export const ScrollToggleContext = createContext({
  disableScroll: false,
  setDisableScroll: (_: boolean) => {
    return;
  },
});

export const useToggleScrollContext: () => {
  disableScroll: boolean;
  setDisableScroll: (value: boolean) => void;
} = () => {
  const { disableScroll, setDisableScroll } = useContext<{
    setDisableScroll: (overlayDisplayed: boolean) => void;
    disableScroll: boolean;
  }>(ScrollToggleContext);
  return { disableScroll, setDisableScroll };
};

const AppWithScrollToggle: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const [disableScroll, setDisableScroll] = useState(false);

  return (
    <ScrollToggleContext.Provider value={{ disableScroll, setDisableScroll }}>
      {children}
    </ScrollToggleContext.Provider>
  );
};

export default AppWithScrollToggle;
