import config from '../config';
import { Language, translations } from './i18n';

export interface Params {
  [key: string]: string | number;
}

interface NestedObject {
  [key: string]: NestedObject | string;
}

function getNestedProperty(
  object: NestedObject | string,
  property: string
): string | undefined {
  if (property.indexOf('.') === -1 && typeof object === 'object') {
    return object[property] as string | undefined;
  }
  const first = property.substring(0, property.indexOf('.'));
  const rest = property.substring(property.indexOf('.') + 1);

  if (typeof object !== 'object' || !object[first]) {
    return undefined;
  }

  return getNestedProperty(object[first] as NestedObject, rest);
}

/**
 * Interpolate a string with placeholders on the form "#{name}",
 * with values from the @params object
 *
 * For plural values, use the form <code>N{<numeric variable>,<singular>,<pluralis>}</code>,
 * i.e. <code>N{count,dokument,dokumenter}</code> and supply the <code>count</code> variable as a parameter such as
 * <code><Translated textKey="…" params={{count: 1}} /></code>
 */
export function interpolate(text: string, params: Params = {}): string {
  let str = text;
  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      const param = params[key];
      str = str.replace(new RegExp(`#\\{${key}\\}`, 'g'), param.toString());
    }
  }
  str = str.replace(
    new RegExp('N\\{([^\\}]+)\\}', 'g'),
    (match: string, key: string) => {
      const keys = key.split(',');
      const param = params[keys[0]];
      const count = parseInt(param.toString(), 10);
      if (count === 1) return keys[1];
      else return keys[2];
    }
  );
  return str.replace(/[#S]\{[^}]+}/g, '');
}

function getFormattedString(
  textKey: string,
  params: Params,
  language: Language
): string {
  if (textKey.length === 0) return '';
  const withSelectedLang = `${language}.${textKey.toLowerCase()}`;
  const withFallbackLang = `nb.${textKey}`;
  const text =
    getNestedProperty(translations, withSelectedLang) ||
    getNestedProperty(translations, withFallbackLang);
  if (!text) {
    if (config.isDevModeEnabled) {
      console.error(
        `Error when translating string, could not find textKey: ${textKey}`
      );
    }
    return '';
  }
  if (!Object.isSealed(params)) {
    return interpolate(text, params);
  }
  if (typeof text !== 'string') {
    const readableText = JSON.stringify(text);
    throw new Error(
      `The text-key ${textKey} did not result in a string but in ${readableText}`
    );
  }
  return text;
}

export function _S(
  textKey: string,
  language: Language = 'nb',
  params: Params = {}
): string {
  return getFormattedString(textKey, params, language);
}
